.corpo{
    justify-content: center;
    padding: 2%;
    border-radius: 0.5rem;
    background-color: aliceblue;
    margin-top: 4%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.geral{
    text-align: center;
    display: flex !important;
    flex-direction: column;
    align-items: center;
}
h3{
    margin-bottom: 2%;
}

.home{
    margin-top: 1%;
    width: 100%;
    display: flex;
    margin-left: 3%;
    justify-content: start;
}


.checks input{
    width: 60px !important;
    height: 30px;
}

.checks label{
    margin-top: 3% !important;
    margin-left: 5px!important;
    height: 30px;
}

 @media(max-width:500px)
{
    .corpo{
        margin: 3%;
    }
    .numero{
        width: 20% !important;
    }
} 

table{
    margin-top: 3%;
}