.form{
    display: flex;
    flex-direction: column;
    width: 30%;
    text-align: center;
    margin-top: 18%;
    line-height: 200%;
}

.div{
    display: flex;
    justify-content: center;
}

#btncad{
    margin-top: 5% !important;
    width: 35%;
}

a:hover{
    color: #fff;
}

.bts{
    display: flex;
}

.containe{
    box-sizing: border-box !important;
    width: 100%;
    height: 100vh;
    background-image: linear-gradient(to right top, #2d00ff, #006cff, #009bff, #00c1ff, #21e1ff);
}

.form input{
    border-radius: 0.375rem;
    border-style: none;
    box-shadow: 2px 2px 1px black;
}

@media (max-width:500px){
    #btncad{
        margin-top: 15% !important;
        margin-bottom: 15% !important;
        width: 100%
    }

    .form{
        display: flex;
        flex-direction: column;
        width: 70%;
        text-align: center;
        margin-top: 20%;
        line-height: 200%;
    }
}