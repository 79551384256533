/* @media (max-width:500px)
{
    
} */

.form-check-input{
    margin-left: -3.25rem;
}

.form-check{
    display: flex !important;
    margin-bottom: 2%

}

.checks{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}